import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mainStore } from '@/store';
import ShowDialog from '@/components/ShowDialog.vue';
let Funcionalidades = class Funcionalidades extends Vue {
    constructor() {
        super(...arguments);
        this.editableFuncionalidade = { ...this.propFuncionalidade };
    }
    openEditDialog() {
        // Abrir o ShowDialog de edição
        this.$refs.editDialog.dialog = true;
        this.editableFuncionalidade = { ...this.propFuncionalidade };
    }
    closeEditDialog() {
        this.$refs.editDialog.dialog = false;
    }
    openDeleteDialog() {
        // Abrir o ShowDialog de exclusão
        this.$refs.deleteDialog.dialog = true;
    }
    closeDeleteDialog() {
        this.$refs.deleteDialog.dialog = false;
    }
    saveChanges() {
        this.$emit('update:novidade', this.editableFuncionalidade);
        this.closeEditDialog();
    }
    deleteFuncionalidade() {
        this.$emit('delete:novidade', this.propFuncionalidade.id);
        this.closeDeleteDialog();
    }
    getNomeEscola() {
        return mainStore.userProfile.company.escola_id == 1 ? 'KNN' : 'Phenom';
    }
    get formattedDescription() {
        return this.propFuncionalidade.description.replace(/\$\{this\.getNomeEscola\(\)\}/g, this.getNomeEscola());
    }
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], Funcionalidades.prototype, "propFuncionalidade", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Funcionalidades.prototype, "propMobile", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Funcionalidades.prototype, "isAdmin", void 0);
Funcionalidades = __decorate([
    Component({
        components: {
            ShowDialog,
        },
    })
], Funcionalidades);
export default Funcionalidades;
