var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isMobile ? 'ma-3' : 'pa-3 ma-3'},[_c('v-row',{staticClass:"articulat-bold"},_vm._l((_vm.tools),function(tool){return _c('v-col',{key:tool.id,attrs:{"cols":"auto"}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getPath(tool)}}},[_vm._v(" "+_vm._s(tool.name)+" ")])])}),1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 7}},[(!_vm.shouldShowNovidadesTreinamento)?[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-icon',{staticStyle:{"border":"1px solid #5E6D7E","border-radius":"4px"}},[_vm._v(" trending_up ")])],1),_c('v-col',{staticClass:"card-title-font"},[_vm._v("Performance")])],1),(!!(_vm.performances.length))?_c('v-row',{staticClass:"articulat-medium gray500--text pt-2 pl-5",staticStyle:{"font-size":"12px","line-height":"15px"}},[_vm._v(" Atualizado por último em: "+_vm._s(_vm.getCurrentPerformanceUpdate(_vm.performances[0].updated_at))+", próxima atualização em "+_vm._s(_vm.getNextPerformanceUpdate(_vm.performances[0].updated_at))+" ")]):_vm._e(),(!_vm.performances.length)?_c('v-row',{staticClass:"flex-grow-1"},_vm._l((12),function(n){return _c('v-col',{key:n,staticClass:"d-flex flex-column",attrs:{"cols":"4"}},[_c('v-skeleton-loader',{staticClass:"flex-grow-1",attrs:{"type":"image"}})],1)}),1):(!_vm.isMobile)?_c('v-row',{staticClass:"flex-grow-1"},_vm._l((_vm.performances),function(performance,index){return _c('v-col',{key:index,staticClass:"d-flex flex-column",attrs:{"cols":"4"}},[_c('Performance',{staticClass:"flex-grow-1",attrs:{"propPerformance":performance}})],1)}),1):_c('v-row',[_c('v-col',[_c('v-slide-group',_vm._l((_vm.performances),function(performance,index){return _c('v-slide-item',{key:index,staticClass:"mx-2",class:index === 0
                  ? 'ml-0 mr-2'
                  : _vm.performances.length - 1 === index
                    ? 'mr-0 ml-2'
                    : ''},[_c('Performance',{attrs:{"propPerformance":performance,"propMobile":true}})],1)}),1)],1)],1)]:_vm._e(),(!_vm.isAdmin)?[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-icon',{staticStyle:{"border":"1px solid #5E6D7E","border-radius":"4px"}},[_vm._v(" star ")])],1),_c('v-col',{staticClass:"card-title-font"},[_vm._v(" Treinamentos ")])],1),_c('v-row',{staticClass:"flex-grow-1"},[_c('v-col',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-card',{staticClass:"card-shadow flex-grow-1 d-flex flex-column",class:_vm.isMobile ? '' : 'pa-6',attrs:{"color":_vm.isMobile ? 'transparent' : ''}},[(!_vm.trainings.length)?_c('v-row',{staticClass:"flex-grow-1"},[(_vm.isLoading == false)?_c('v-col',{staticClass:"card-title-font"},[_c('span',[_vm._v("Não há treinamentos agendados")])]):_vm._e(),_vm._l((4),function(n){return _c('v-col',{key:n,staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[(_vm.isLoading == true)?_c('v-skeleton-loader',{staticClass:"flex-grow-1",attrs:{"type":"list-item-two-line"}}):_vm._e()],1)})],2):(!_vm.isMobile)?_c('v-row',{staticClass:"flex-grow-1"},_vm._l((_vm.trainings),function(training,index){return _c('v-col',{key:index,staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('Treinamentos',{staticClass:"flex-grow-1",attrs:{"propTraining":training}})],1)}),1):_c('v-row',[_c('v-col',[_c('v-slide-group',_vm._l((_vm.trainings),function(training,index){return _c('v-slide-item',{key:index,staticClass:"mx-2",class:index === 0
                        ? 'ml-0 mr-2'
                        : _vm.performances.length - 1 === index
                          ? 'mr-0 ml-2'
                          : ''},[_c('Treinamentos',{attrs:{"propTraining":training,"propMobile":true}})],1)}),1)],1)],1)],1)],1)],1)]:_vm._e()],2),_c('v-col',{staticClass:"flex-column",attrs:{"cols":_vm.isMobile ? 12 : 5}},[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-icon',{staticStyle:{"border":"1px solid #5E6D7E","border-radius":"4px"}},[_vm._v(" star ")])],1),_c('v-col',{staticClass:"card-title-font"},[_vm._v(" Novidades ")]),_c('v-spacer'),(_vm.isAdmin)?_c('v-btn',{staticClass:"create-button",attrs:{"color":"primary","rounded":""},on:{"click":_vm.openAddDialog}},[_vm._v(" Criar "),_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-row',{staticClass:"flex-grow-1",staticStyle:{"align-items":"flex-start"}},[_c('v-col',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-card',{staticClass:"card-shadow flex-grow-1 d-flex flex-column",class:_vm.isMobile ? '' : 'pa-6 golaco-cara',attrs:{"color":_vm.isMobile ? 'transparent' : ''}},[(!_vm.isMobile)?_c('v-row',{staticClass:"flex-grow-1"},_vm._l((_vm.novidades),function(novidade,index){return _c('v-col',{key:index,staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}})],1),_c('Funcionalidades',{staticClass:"flex-grow-1",attrs:{"propFuncionalidade":novidade,"propMobile":_vm.isMobile,"isAdmin":_vm.isAdmin},on:{"update:novidade":_vm.handleUpdateNovidade,"delete:novidade":_vm.handleDeleteNovidade}})],1)}),1):_c('v-row',[_c('v-col',[_c('v-slide-group',_vm._l((_vm.novidades),function(novidade,index){return _c('v-slide-item',{key:index,staticClass:"mx-2",class:index === 0 ? 'ml-0 mr-2' : _vm.novidades.length - 1 ===
                      index ? 'mr-0 ml-2' : ''},[_c('Funcionalidades',{staticClass:"flex-grow-1",attrs:{"propFuncionalidade":novidade,"propMobile":true,"isAdmin":_vm.isAdmin},on:{"update:novidade":_vm.handleUpdateNovidade,"delete:novidade":_vm.handleDeleteNovidade}})],1)}),1)],1)],1)],1)],1)],1)],1)],1),_c('ShowDialog',{ref:"showCreateNovidade",attrs:{"propTitle":"Adicionar Novidade","propCancel":"","propSmall":true}},[_c('v-text-field',{attrs:{"label":"Título"},model:{value:(_vm.newNovidade.title),callback:function ($$v) {_vm.$set(_vm.newNovidade, "title", $$v)},expression:"newNovidade.title"}}),_c('v-textarea',{attrs:{"label":"Descrição"},model:{value:(_vm.newNovidade.description),callback:function ($$v) {_vm.$set(_vm.newNovidade, "description", $$v)},expression:"newNovidade.description"}}),_c('v-checkbox',{attrs:{"label":"Novo?"},model:{value:(_vm.newNovidade.is_new),callback:function ($$v) {_vm.$set(_vm.newNovidade, "is_new", $$v)},expression:"newNovidade.is_new"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"save-button",attrs:{"color":"primary","rounded":"","depressed":""},on:{"click":_vm.addNovidade}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")]),_vm._v(" Adicionar ")],1)],1)],1),_c('MarketingPopupComponent')],1)}
var staticRenderFns = []

export { render, staticRenderFns }