import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import { mainStore, userActionStore, userToolStore } from '@/store';
// @ts-ignore
import Caixa from '@/views/main/dashboard/Caixa.vue';
// @ts-ignore
import Cobranca from '@/views/main/dashboard/Cobranca.vue';
// @ts-ignore
import Reposicao from '@/views/main/dashboard/reposicao/Reposicao.vue';
// @ts-ignore
import Reforco from '@/views/main/dashboard/Reforco.vue';
// @ts-ignore
import Professor from '@/views/main/dashboard/Professor.vue';
// @ts-ignore
import Recepcao from '@/views/main/dashboard/Recepcao.vue';
// @ts-ignore
import Ocorrencia from '@/views/main/dashboard/Ocorrencia.vue';
// @ts-ignore
import Retencao from '@/views/main/dashboard/Retencao.vue';
// @ts-ignore
import Rematricula from '@/views/main/dashboard/Rematricula.vue';
// @ts-ignore
import Contrato from '@/views/main/dashboard/Contrato.vue';
// @ts-ignore
import Turma from '@/views/main/dashboard/Turma.vue';
// @ts-ignore
import EntregaLivro from '@/views/main/dashboard/EntregaLivro.vue';
// @ts-ignore
import DashboardAlunoKnnComponent from '@/components/DashboardAlunoKnnComponent.vue';
import NewDashboard from './novoDashboard/NewDashboard.vue';
import _ from 'lodash';
let Dashboard = class Dashboard extends Vue {
    get userActions() {
        return userActionStore.userActions;
    }
    handlePedagogico() {
        this.$router.push('/main/pedagogico/all');
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get userActionsDashboardFinanceiroAllow() {
        return userActionStore.dashboardFinanceiroAllow;
    }
    get userActionsVisualizarDashboard() {
        return userActionStore.visualizarDashboardPerformance;
    }
    get userActionsDashboardMeuCaixaAllow() {
        return userActionStore.dashboardMeuCaixaAllow;
    }
    get userActionsDashboardReposiçãoAllow() {
        return userActionStore.dashboardReposiçãoAllow;
    }
    get userActionsDashboardCobrançaAllow() {
        return userActionStore.dashboardCobrancaAllow;
    }
    get userActionsDashboardPedagogicoAllow() {
        return userActionStore.dashboardPedagogicoAllow;
    }
    get userActionsdashboardRecepcaoAllow() {
        return userActionStore.dashboardRecepcaoAllow;
    }
    get userActionsDashboardTarefasEReunioesPendentesAllow() {
        return userActionStore.dashboardTarefasEReunioesPendentesAllow;
    }
    get userActionsDashboardProfessorAllow() {
        return userActionStore.dashboardProfessorAllow;
    }
    get userActionsDashboardRetencaoAllow() {
        return userActionStore.dashboardRetencaoAllow;
    }
    get userActionsDashboardRematriculaAllow() {
        return userActionStore.dashboardRematriculaAllow;
    }
    get userToolAnaliseContrato() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 52);
    }
    get userToolCobranca() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 65);
    }
    get userToolEntregaDeLivro() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 66);
    }
    get userToolFinanceiro() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 67);
    }
    get userToolMeuCaixa() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 68);
    }
    get userToolProfessor() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 70);
    }
    get userToolRecepcao() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 71);
    }
    get userToolReforco() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 72);
    }
    get userToolRematricula() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 73);
    }
    get userToolReposicao() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 74);
    }
    get userToolRetencoes() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 75);
    }
    get userToolTarefasEReunioesPendentes() {
        return _.find(userToolStore.userTools, (i) => i.tool_id == 76);
    }
    get userActionsDashboardReforcoAllow() {
        return userActionStore.dashboardReforcoAllow;
    }
};
Dashboard = __decorate([
    Component({
        components: {
            Caixa,
            Cobranca,
            Contrato,
            EntregaLivro,
            Ocorrencia,
            Professor,
            Recepcao,
            Rematricula,
            Reposicao,
            Retencao,
            Turma,
            Reforco,
            DashboardAlunoKnnComponent,
            NewDashboard,
        },
    })
], Dashboard);
export default Dashboard;
