import { __decorate } from "tslib";
import { mainStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { isAfter, isEqual, startOfDay } from 'date-fns';
let MarketingPopup = class MarketingPopup extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.deadlineDate = "2024-11-10T23:59:59";
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get popupTitle() {
        return `Estão abertas as inscrições para o ${this.getNomeMarketingDigital()} para o mês de Novembro!`;
    }
    get deadlineDateFormatted() {
        const date = new Date(this.deadlineDate);
        return date.toLocaleDateString('pt-BR', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
        });
    }
    checkPopupDisplay() {
        this.dialog = false;
        const deadlineDate = startOfDay(new Date(2024, 10, 10));
        const today = startOfDay(new Date());
        if (isAfter(today, deadlineDate)) {
            return;
        }
        const savedUserId = localStorage.getItem('DRIVE-marketing_digital_userID');
        const lastSeenDate = localStorage.getItem('DRIVE-marketing_digital_popupLastSeen');
        const isUsuariosDiferentes = Number(savedUserId) !== this.userProfile.id;
        const lastSeenDateObj = lastSeenDate ? startOfDay(new Date(lastSeenDate)) : null;
        const isMesmoDia = lastSeenDateObj ? isEqual(today, lastSeenDateObj) : false;
        if ((!savedUserId && !lastSeenDate) || isUsuariosDiferentes || !isMesmoDia) {
            localStorage.setItem('DRIVE-marketing_digital_userID', this.userProfile.id.toString());
            localStorage.setItem('DRIVE-marketing_digital_popupLastSeen', today.toISOString()); // Salva a data atual
            this.dialog = true;
        }
    }
    redirect() {
        this.dialog = false;
        this.$router.push("/main/DrivingMarketing/all");
    }
    mounted() {
        this.checkPopupDisplay();
    }
};
MarketingPopup = __decorate([
    Component
], MarketingPopup);
export default MarketingPopup;
